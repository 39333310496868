.searchWrapper {
    width: 600px;
    height: 48px;
    display: flex;
    align-items: center;
    background-color: transparent;

    @media screen and (max-width: 991px) {
        width: 400px;
    }
}

.styledForm {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    width: 600px;
    color: #77798C;
    background-color: #ffffff;
    box-shadow: 0 21px 36px rgba(0, 0, 0, 0.05);

    @media screen and (max-width: 768px) {
        width: 100%;
        z-index: 100;
        box-shadow: none;
    }
}

.styledInput {

    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 15px;
    padding-left: 20px;
    padding-right: 20px;
    height: 48px;
    color: #77798C;
    background-color: inherit;
    border: 0;

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder {
        font-size: 20px;
        color: #4e4e4e;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.styledInput:focus {
    outline: none;
}


.ui.button.styledSearchButton {

    background-color: #ffffff;
    color: #009E7F;
    font-size: 15px;
    font-weight: 700;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 48px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 0;
    outline: 0;
    padding-left: 30px;
    cursor: pointer;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0;
    border-radius: 0;
}

.drawer ul {
    z-index: 900 !important;
    left: 0;
    padding-left: 20px;
    padding-right: 20px;
}

.drawer {
    position: absolute;
    max-height: 400px;
    width: 600px;
    overflow-y: auto;
    pointer-events: auto;
    border-radius: 5px;
    background-color: #fff;
    opacity: 1;
    box-shadow: 0 21px 36px #28222226;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    @media screen and (max-width: 768px) {
        width: 100%;
        max-height: 600px;
        vertical-align: top;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.drawer::-webkit-scrollbar {
    display: none;
}

.drawer.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 1s ease;
    z-index: 900 !important;

    @media screen and (max-width: 768px) {
        top: 50px;
    }
}

.drawer.inactive {
    opacity: 0;
    visibility: visible;
    transform: translateY(-500px);
    transition: 1s ease;
    z-index: 0;
    display: none;
}

.drawer.navbar {
    position: absolute;
}

.drawer li {
    list-style: none;
    padding: 8px 0px;
    width: 100%;
    cursor: default;
    border-radius: 3px;
    display: block;
}

.drawer li:hover {
    background: #efefef;
}

.drawer .slick-container-mobile {
    max-width: 100vw;
    max-height: 90px !important;
    height: 90px;
}

.horizontalListTitle {
    display: block;
    font-weight: 500;
    font-size: x-small;
    color: #77798C;
    margin-bottom: 5px;
    text-align: left;
}

.horizontalListItemCaption {
    font-family: Lato, Arial, font-family;
    display: inline-block;
    line-height: 12pt;
    text-align: center;
    font-size: smaller;
    font-weight: 650;
    width: 100%;
    overflow-y: visible;
    color: #353925 !important;
    text-align: center;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    /* border: solid 1px #0a7041;
    margin: 3px; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.horizontalListItemCaption::-webkit-scrollbar {
    display: none;
}

.horizontalListItemCaption:hover {
    color: #069e57 !important;
    /* font-size: larger; */
    cursor: pointer;
}

.searchSlideItem {
    cursor: pointer;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.searchSlideItem::-webkit-scrollbar {
    display: none;
}

.searchSlideItem>span {
    max-width: 100%;
    height: 2px;
}

.searchSlideItem .slick-menu-img {
    width: 40px;
    height: 40px;
    text-align: center;

    @media screen and (max-width: 991px) {
        width: 30px;
        height: 30px;
    }
}

.horizontalListItemImage {
    width: 30px;
    height: 30px;
    padding-bottom: 5px;
}

.horizontalListItem {
    margin: 6px 0px 6px 0px;
    text-align: center;
    display: inline-block;
    max-width: 80px;
    height: 50px;
    border-radius: 25%;
    cursor: pointer;
    touch-action: none;
}

.horizontalList {

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.horizontalList::-webkit-scrollbar {
    display: none;
}


.drawer .ui.divided.relaxed.list {
    margin: 0px;

    :hover {
        background: #efefef18;
    }
}

.drawer .ui.list>.item a.header {
    padding-top: 4px;
    color: #0a7041 !important;
    text-align: left;
}

.drawer ul .ui.list>.item {
    padding-top: 4px;
    color: #0a7041 !important;
}

.drawer .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin: 0;
}

.drawer .slick-container {
    padding-left: 0px;
    padding-right: 0px;
    max-height: 135px;
}

.drawer .slick-container-tablet {
    padding-left: 0px;
    padding-right: 0px;
}

.drawer .slick-list {
    min-height: 30px;
    max-height: 100px !important;
    height: auto !important;
}