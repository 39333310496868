/* For Firefox */
@-moz-document url-prefix() {

  html,
  body {
    scrollbar-width: thin;
    /* Makes the scrollbar thin */
    scrollbar-color: #10985d transparent !important;
    /* Thumb color and track color */
  }
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
  /* Width of the scrollbar */
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: transparent !important;
  /* Transparent background for the scrollbar track */
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #10985d !important;
  /* Color of the scrollbar thumb */
  border-radius: 10px !important;
  /* Round edges of the scrollbar thumb */
}

html::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover {
  background: #086c41 !important;
  /* Color of the scrollbar thumb when hovered */
}


.paddedPage {
  padding: 0px 100px 0px;
  margin: 0 auto;
  max-width: 1700px;
}

.simply-paddedPage {
  padding: 15px 10px 0px;
  /* margin: 0 auto; */
}

.simply-paddedPage-about {
  padding: 15px 100px 0px;
  margin: 0 auto;
  max-width: 1700px;
}

.very-padded-page {
  padding: 20px 20px 0;
  margin: 0 auto;
  max-width: 600px;
}

.font-size-16 {
  font-size: 16px !important;
}

.ad-top-padding {
  padding-top: 35px;
}

.no-padding {
  padding: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

.ui.modal>.content.no-padding {
  padding: 0px !important;
}

.ui.modal {
  position: initial !important;
}

@media only screen and (max-width: 767px) {
  .ui.stackable.grid>.row>.column.no-padding {
    padding: 0 !important;
  }
}

.sidebar.ui.vertical.menu .account-button>.text {
  margin-right: 10px;
}

.account-button .dropdown {
  display: none;
}

/* PRODUCT CARD ITEM */

.productCardItem {
  overflow: hidden;
  padding: 15px;
}

.productCardItem h3 {
  margin: 0;
}

.productCardItem h3 div {
  overflow: hidden;
}

.productCardItem .subText {
  font-weight: normal;
  font-size: 14px;
  margin: 0.3em 0 0;
  line-height: 1.2em;
}

.subTextTwo {
  color: #aaa;
  font-size: 11px;
}

.productCardItem .underTitle {
  color: #555;
  font-weight: bold;
  font-size: 15px;
  line-height: 1em;
  margin-top: 1em;
}

.productCardItem .underTitle>span {
  color: #aaa;
  font-size: 0.9em;
  font-weight: 300;
}

.productCardItem .leftSection>div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}

.productCardItem .approvedCount {
  position: absolute;
  background: #2285d0;
  top: 0;
  text-align: center;
  width: 100%;
  color: white;
  font-size: 0.8em;
  line-height: 1.6em;
  font-style: normal;
  pointer-events: none;
  border-radius: 25px;
  width: 19px;
  height: 19px;
  top: -16px;
  right: 0px;
}

.productCardItem .expansion,
.productCardItem .expansion.-exit {
  max-height: 0;
  transition: max-height 0.3s, margin-top 0.3s, opacity 0.3s;
  overflow: hidden;
  margin-top: 0em;
  opacity: 0;
}

.productCardItem .expansion.-enter-done {
  max-height: 50vh;
  margin-top: 2em;
  opacity: 1;
}

.productCardItem .expansion .segment {
  overflow-y: hidden;
}

.productCardItem .expansion.-enter-done .segment {
  overflow-y: auto;
}

.productCardItem hr {
  width: 1px;
  height: 150%;
  border: 0;
  background: #ddd;
  margin: 0;
}

/* ACCOUNT CARD ITEM - MOBILE */

.fresnel-container.fresnel-at-mobile .productPage {
  margin: 0;
}

.fresnel-container.fresnel-at-mobile .productPage>.ui.card {
  margin: 0 0 15px;
  border-radius: 0;
  padding: 10px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  border: 1px solid #ddd;
  background-color: #fcfcfc;
  border-width: 1px 0;
}

.fresnel-container.fresnel-at-mobile .productCardItem .subText a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.fresnel-container.fresnel-at-mobile .productCardItem .expansion.-enter-done {
  margin-top: 0;
}

/* FLEXBOX GLOBAL SELECTORS (with Fallbacks) */

.fxDisplay {
  display: flex;
}

.fxDirCol {
  flex-direction: column;
}

.fxFixed {
  flex: 0 0 auto;
}

.fxWrap {
  flex-wrap: wrap;
}

.fxStretch {
  flex: 1 1 auto;
}

.fxAlignCenter {
  align-items: center;
}

.fxTextAlignCenter {
  text-align: center !important;
}

.fxTextAlignJustify {
  text-align: justify !important;
}

.fxAlignStart {
  align-items: flex-start;
}

.fxAlignEnd {
  align-items: flex-end;
}

.fxJustifyStart {
  justify-content: start;
}

.fxJustifyCenter {
  justify-content: center;
}

.fxJustifyEnd {
  justify-content: flex-end;
}

.fxJustifyEven {
  justify-content: space-evenly !important;
}

.fxJustifyBetween {
  justify-content: space-between;
}

.ui.centered.displayGrid .ui.raised.card.superMarketCard {
  padding: 1rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1) inset;
  transition: background-color 0.3s, box-shadow 0.2s, transform 0.2s;
  will-change: background-color, box-shadow, transform;
  background: #fcfcfc;
  width: 250px;
  border-radius: 3px;
  border: 1px solid #eee;
  transform: translateX(0) translateY(0);
  cursor: pointer;
}

.ui.centered.displayGrid .ui.raised.card.superMarketCard img {
  object-fit: contain;
  height: 60px;
  transition: opacity 0.5s, transform 0.3s;
  will-change: opacity, transform;
}

.ui.centered.displayGrid .ui.raised.card.superMarketCard .header {
  font-size: 1.1rem;
  text-align: center;
}

.ui.centered.displayGrid .ui.raised.card.superMarketCard .description {
  font-size: 0.9em;
  line-height: 1.3em;
  /* opacity: 0.8; */
}

@media (hover: hover) and (pointer: fine) {
  .ui.centered.displayGrid .ui.raised.card.superMarketCard:not(.selectedsuperMarketCard):hover {
    transform: scale(1.05);
    box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1),
      0px 0px 0px rgba(0, 0, 0, 0.1) inset;
    background: #fff;
  }

  .ui.centered.displayGrid .ui.raised.card.superMarketCard:not(.selectedsuperMarketCard):hover img {
    transform: scale(1.4);
  }
}

.ui.centered.displayGrid .ui.raised.card.superMarketCard.selectedsuperMarketCard {
  transform: translateX(1px) translateY(1px);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1),
    3px 4px 4px rgba(0, 0, 0, 0.1) inset, 0 0 0 2px #1fb976;
}

.superMarketImage {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  background-color: transparent !important;
}

.inline-superMarketImage {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  background-color: transparent !important;
  max-height: 25px;
  object-fit: contain;
}

.inline-superMarketImage.chat {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  background-color: transparent !important;
  max-height: 17px;
  object-fit: contain;
}

.inline-superMarketImage[src$=".png"] {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(0.1px 0.1px 0.1px #222);
}

.compare-div {
  background-color: rgba(201, 226, 102, 0.475);
  color: rgb(5, 126, 12);
  border-radius: 3px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  text-align: center;
}

.compare-div:hover {
  cursor: pointer;
}

.price-trend-div {
  /* background-color: rgba(140,231, 235, 0.475); */
  background-color: rgba(102, 176, 226, 0.475);
  color: rgb(5, 7, 126);
  border-radius: 3px;
  width: fit-content;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
}

.price-trend-div {
  cursor: pointer;
}

.button-as-link {
  background: transparent !important;
  padding: 0px !important;
  margin: 3px 3px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, font-family;
  font-size: 1rem;
  font-weight: 700;
  border: none;
}

a.grey {
  color: #777;
}

a.grey:hover {
  text-decoration: underline;
}

/* SEMANTIC-UI OVERWRITES - HorizontalMobileMenu */

.ui.menu.horizontalMobileMenu {
  overflow-x: auto;
  padding-bottom: 2px;
  border: 0 !important;
  box-shadow: 0 -2px 0 0px rgb(0 0 0 / 15%) inset !important;
}

.ui.menu.horizontalMobileMenu::-webkit-scrollbar {
  display: none;
}

.locationsWidgetPoint .icon {
  position: absolute;
  /* color: #fb8033; */
  font-size: 30px !important;
  margin: 0;
  transform: translateX(-50%) translateY(-100%);
  text-shadow: -1px -1px 1px white, -1px 1px 1px white, 1px 1px 1px white,
    1px -1px 1px white;
}

.locationsWidgetPoint>span {
  white-space: nowrap;
  transform: translateX(-50%) translateY(10px);
  text-align: center;
  position: absolute;
  text-shadow: -2px -2px 2px white, -2px 2px 2px white, 2px 2px 2px white,
    2px -2px 2px white;
  /* color: #fb8033; */
  font-size: 14px;
  font-weight: bold;
}

.white-icon {
  color: white !important;
}

.round-background {
  background: #2285d0;
  border-radius: 25px;
}

.ui.raised.card.priceListCard {
  padding: 1rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1) inset;
  transition: background-color 0.3s, box-shadow 0.2s, transform 0.2s;
  will-change: background-color, box-shadow, transform;
  background: #fcfcfc;
  width: 300px;
  border-radius: 3px;
  border: 1px solid #eee;
  transform: translateX(0) translateY(0);
  /* cursor: pointer; */
}

.ui.raised.card.priceListCard img {
  /* margin: 10px 10px 10px 10px; */
  padding-top: 20px;
  object-fit: contain;
  max-height: 60px;
  min-height: 60px;
  max-width: 150px;
  transition: opacity 0.5s, transform 0.3s;
  will-change: opacity, transform;
}

.ui.raised.card.priceListCard .header {
  font-size: 1.1rem;
  text-align: center;
}

.ui.raised.card.priceListCard .description {
  font-size: 0.9em;
  line-height: 1.3em;
  /* opacity: 0.8; */
}

.ui.raised.card.priceListCard .item {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.1em;
  cursor: default;
  /* opacity: 0.8; */
}

@media (hover: hover) and (pointer: fine) {
  /* .ui.raised.card.priceListCard:not(.selectedPriceListCard):hover {
    transform: scale(1.05);
    box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1),
      0px 0px 0px rgba(0, 0, 0, 0.1) inset;
    background: #fff;
  }

  .ui.raised.card.priceListCard:not(.selectedPriceListCard):hover
    img {
    transform: scale(1.2);
  }

   .ui.raised.card.priceListCard:not(.selectedPriceListCard):hover .item {
      font-size: 13px;
      font-weight: 400;
      line-height: 1.2em;
    } */
}

/* ----------------------------------------------------------------
	Password Input Styles
-----------------------------------------------------------------*/
.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

.pass-icon-left {
  position: absolute;
  top: 22%;
  right: 10%;
}

.pass-icon-left:hover {
  color: #2285d0;
  cursor: pointer;
}

.pass-icon {
  position: absolute;
  top: 22%;
  right: 2%;
}

.pass-icon:hover {
  color: #2285d0;
  cursor: pointer;
}

.bluePlaceholder::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #2285d0 !important;
  /* opacity: 1 !important; Firefox */
}

.bluePlaceholder:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2285d0 !important;
}

.bluePlaceholder::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #2285d0 !important;
}

.ctGreenPlaceholder::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #10985d !important;
  /* opacity: 1 !important; Firefox */
}

.ctGreenPlaceholder:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #10985d !important;
}

.bluePlactGreenPlaceholderceholder::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #2285d0 !important;
}

.blueColor {
  color: #2285d0 !important;
}

.darkBlueColor {
  color: #0264af !important;
}

.greenColor {
  color: #21973f !important;
}

.tealColor {
  color: #03989e !important;
}

.redColor {
  color: #db3737 !important;
}

.yellowColor {
  color: #dbbd37 !important;
}

.linkable:hover {
  /* text-decoration: underline; */
  cursor: pointer;
}

.linkable-with-decoration:hover {
  text-decoration: underline;
  color: #777;
  cursor: pointer;
}

/* Dropdown CSS */
.drpHeader {
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.drpItem {
  margin-left: 7px !important;
}

.drpItem::before {
  content: "";
}

.ui.vertical.sidebar.menu>.item:first-child:before {
  display: none !important;
}

.ui.visible.left.overlay.sidebar {
  position: sticky;
}

/* ----------------------------------------------------------------
	Marker Style
-----------------------------------------------------------------*/
.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px white;
}

.marker {
  border: solid 2px white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.marker-label {
  position: absolute;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  pointer-events: auto;
  letter-spacing: 0.5px;
  font-weight: 600;
  line-height: 13px;
  background-color: #f9f5f2;
  color: #575757;
  font-size: 11px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 50%);
  margin-top: 0;
  transform: translateX(-50%) translateX(5px) translateY(0px);
  border-radius: 8px;
  padding: 1px 7px;
}

.hoverAnotation:hover>.marker {
  background-color: rgb(252, 56, 74) !important;
}

.sold {
  background-color: #03989e !important;
  color: white !important;
}

.div-as-link:hover {
  color: #10985d;
  cursor: pointer;
}

.headerWithSearchAdvancedSearch,
.headerWithSearchAdvancedSearch.transition-enter-active,
.headerWithSearchAdvancedSearch.transition-exit-active {
  min-height: 0 !important;
  opacity: 0;
  max-height: 0;
  margin: 0;
  transition: opacity 0.3s, max-height 0.3s, margin 0.3s;
}

.headerWithSearchAdvancedSearch.transition-enter-active,
.headerWithSearchAdvancedSearch.transition-enter-done {
  opacity: 1;
  max-height: 50vh;
  margin: 1rem 0;
}

/* ----------------------------------------------------------------
	Divider Style
-----------------------------------------------------------------*/

.smaller-divider {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.adSponsored-divider {
  margin-top: 7px !important;
  margin-bottom: 2px !important;
}

/* ----------------------------------------------------------------
	Ad Style
-----------------------------------------------------------------*/
.ad-panel-column {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  bottom: 0;
  /* background: green; */
  max-height: 100vh !important;
  /* height: 100vh !important; */
  width: 12vw !important;
  /* overflow: hidden; */
}

.ad-panel-column.left {
  padding-left: 7px;
}

.ad-panel-column.right {
  padding-right: 7px;
}

.adSponsored {
  color: #777;
  font-size: 13px;
  padding-left: 7px;
  padding-right: 7px;
  margin-top: 0px;
  padding-top: 0px;
}

.adSponsored.left {
  text-align: left;
}

.adSponsored.right {
  text-align: right;
}

.adImage {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain !important;
}

.adImage.small {
  max-height: 45vh;
}

.adImage.large {
  max-height: 98vh;
}

.adImage-shrink {
  max-height: 350px !important;
}

.adImage-center {
  vertical-align: middle;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

/* ----------------------------------------------------------------
	New balloon
-----------------------------------------------------------------*/
.new-header {
  position: absolute;
  margin-bottom: 23px;
  margin-left: -23px;
  font-size: 10px;
  font-weight: 700;
  color: white;
  letter-spacing: 1px;
  background: #086c41;
  height: 17px;
  padding: 7px;
  padding-top: 3px;
  border-radius: 7px;
  rotate: 340deg;
}

.new-header-mobile {
  display: inline !important;
  margin-bottom: 59px !important;
  margin-right: 7px !important;
  font-size: 13px;
  font-weight: 600;
  color: white;
  letter-spacing: 1.3px;
  background: #086c41;
  padding: 9px;
  padding-top: 7px;
  border-radius: 7px;
}

/* ----------------------------------------------------------------
	Ad Placeholder
-----------------------------------------------------------------*/

.adPanelPlaceholder {
  margin: "auto";
  border-radius: 10px;
  border: dashed 2px #2285d0;
  height: 100%;
}

/* PHOTO WIDGET STYLING */

.photoWidgetDropzone,
.photoWidgetCropper,
.photoWidgetPreview {
  border: dashed 3px;
  border-color: #eee;
  border-radius: 5px;
  text-align: center;
  height: 250px;
  width: 250px;
  outline: 0;
}

.photoWidgetDropzone {
  cursor: pointer;
  color: #ddd;
}

.photoWidgetDropzone.active {
  color: #aaa;
}

/* CP GLOBAL LAYOUT SELECTORS */
.cpGrid {
  display: grid;
}

.cpGrid3ec {
  grid-template-columns: repeat(3, 1fr);
}

.cpGrid2ec {
  grid-template-columns: repeat(2, 1fr);
}

.cpGrid1ec {
  grid-template-columns: repeat(1, 1fr);
}

.cpMarginT3 {
  margin-top: 3em;
}

.teal-label {
  color: white !important;
  background-color: #03989e !important;
  border-color: #038388 !important;
  max-width: 135px;
}

/* COVER */
/* header::after {
  background: white;
  top: 0;
  z-index: 2;
} */

/* HEADER CONTENT */
header>div {
  background: white;
  height: 64px;
  /* padding: 20px; */
  position: sticky;
  -webkit-position: sticky;
  top: 0px;
  /* compensate for shadow with negative margin */
  margin-top: -16px;
  /* content should fall over shadow and cover */
  z-index: 3;
}

header>div h3 {
  margin: 0;
}

/* HEADER with drop-shadow on scroll */
header {
  /* height: 80px;  */
  position: sticky;
  -webkit-position: sticky;
  /* negative top allows for 16 pixels of movement before sticking */
  top: -16px;
  /* make sure header overlaps main*/
  z-index: 100;
  color: white;
  text-align: center;
  /* fix weird flickering issue in chrome: https://stackoverflow.com/a/22224884/286685 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* PSEUDO ELEMENTS to create drop-shadow */
/* header::before,
header::after {
  content: "";
  display: block;
  height: 16px;
  position: sticky;
  -webkit-position: sticky;
} */

/* SHADOW */
/* header::before {
  top: 48px;
  box-shadow: 0 8px 6px -6px black;
} */

/* COVER */
/* header::after {
  background: white;
  top: 0;
  z-index: 2;
} */

/* HEADER CONTENT */
header>div {
  background: white;
  height: 64px;
  position: sticky;
  -webkit-position: sticky;
  top: 0px;
  margin-top: -16px;
  z-index: 3;
}

header>div h3 {
  margin: 0;
}

.grouped-product-title {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 15px;
  text-decoration: none;
  color: #000;
  line-height: 120%;
}

.grouped-product-title.chat {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 100;
  line-height: 120%;
}


.grouped-product-image {
  max-height: 200px;
  min-height: 200px;
  object-fit: contain;
  /* width: 100%; */
  margin: auto;
  display: block;
  aspect-ratio: 1;
  overflow-clip-margin: content-box;
  overflow: clip;
  position: fixed;
}

.grouped-product-image.chat {
  max-height: 80px !important;
  min-height: 80px !important;
}

.ui.popup.catalogue-image-popup {
  max-width: 650px !important;
  /* max-width: 800px; */
  margin: 0 auto;
}

.grouped-product-image-popup {
  max-height: 600px;
  max-width: 800px;
  width: 100% !important;
  height: auto !important;
  image-rendering: auto !important;
  object-fit: contain;
  margin: auto;
  display: block;
  overflow: clip;
  position: fixed;
}

.grouped-product-image-mobile {
  max-height: 100px;
  min-height: 100px;
  object-fit: contain;
  /* width: 100%; */
  margin: auto;
  display: block;
  aspect-ratio: 1;
  overflow-clip-margin: content-box;
  overflow: clip;
  position: fixed;
}

.tracker-detail-product-image {
  max-height: 120px;
  min-height: 120px;
  object-fit: contain;
  /* width: 100%; */
  margin: auto;
  display: block;
  aspect-ratio: 1;
  overflow-clip-margin: content-box;
  overflow: clip;
  position: fixed;
}

.group-compare-new {
  color: #e96f2d;
  font-weight: 400;
  font-size: 15px;
}

.group-compare-new:hover {
  cursor: pointer;
}

.small-letters {
  font-size: 11px;
  text-align: center;
  white-space: unset;
}

.address {
  font-weight: normal;
  font-size: 14px;
  margin: 0.3em 0 0;
  line-height: 1.2em;
  color: #059228;
}

.available-delivery-group {
  text-align: center !important;
  font-size: 13px;
  margin-top: 3px;
}

.checkout-market-box {
  /* padding: 5px 0px 5px 0px; */
  font-size: 15px;
}

.checkout-market-minimum {
  color: red !important;
  text-align: right;
  font-size: small;
}

.checkout-totals-box {
  padding: 1px 0px 2px 0px;
  font-size: 13px;
}

.checkout-product-title {
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  margin-top: 3px;
  text-decoration: none;
  color: #000;
  line-height: 120%;
}

.oder-processing-market {
  font-size: 15px;
  line-height: 25px;
}

.oder-detail-item {
  font-size: 12px;
  line-height: 32px;
}

.order-list {
  overflow-y: auto;
  padding-right: 10px;
  /* padding-left: 12px; */
  max-height: 200px;
}

.credit-list-item {
  font-size: 17px;
  line-height: 35px;
}

.clickable {
  cursor: pointer;
}

.hub-order-status-button {
  cursor: pointer;
  margin: auto;
  text-align: center;
  margin-right: 2px;
}

.hub-order-status-button>div {
  padding-left: 8px;
  padding-right: 8px;
}

.hub-order-status-button span {
  font-size: 13px;
  white-space: pre;
}

.hub-order-status-button>div:hover {
  box-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
  transition: opacity 0.5s, transform 0.3s;
  will-change: opacity, transform;
}

.feature-card {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}

.feature-card>.fxDisplay {
  border: none !important;
  border-top: none !important;
}

.slick-prev:before,
.slick-next:before {
  color: #c9e165 !important;
  /* background :#059228 !important; */
}

.slick-container {
  padding-left: 25px;
  padding-right: 25px;
  max-width: 100%;
  max-height: 95px;
}

.slick-container-tablet {
  padding-left: 25px;
  padding-right: 25px;
  max-width: 98vw;
  max-height: 95px;
}

.slick-container-mobile {
  max-width: 90vw;
  max-height: 115px;
  height: 105px;
}

.slick-menu-img {
  width: 60px;
  height: 60px;
  margin: auto;
  /* border-radius: 50%; */
  object-fit: contain;
  transform: scale(0.8);
}

.slick-menu-span {
  text-align: center;
  font-size: 13px;
  /* white-space: pre; */
  /* word-break: break-all; */
  white-space: pre-line;
}

.slick-option {
  max-width: 85px;
  border-radius: 15%;
  height: 100px;
  padding-top: 2px;
  pointer-events: auto;
  transform: scale(1);
  transition: all 0.4s;
}

.slick-option:hover {
  opacity: 1;
  /* transform: scale(1.02); */
}

.slick-container:hover .slick-option:not(:hover) {
  opacity: 0.8;
  transform: scale(0.9);
}

.slick-option-selected img {
  border: #10985d 2.5px solid;
  border-radius: 50%;
}

.slick-option-selected .slick-menu-span {
  font-weight: bold;
}

.preparing-order.selected {
  background-color: #1fb976 !important;
}

.preparing-order:not(.selected) {
  opacity: 0.2;
  /* background-color: #aaa !important; */
  /* transform: scale(0.9); */
}

.partially-packed-item {
  background-color: rgba(210, 105, 30, 0.322);
}

.refunded-item {
  background-color: #db37375e;
}

.packed-order-item {
  background-color: #05922854;
}

.disabled-text {
  color: #c0c0c0;
  font-size: 13px;
}

.email-verify-container {
  color: #000 !important;
  border-radius: 8px !important;
  background: #ffffff !important;
  border: 1px solid #34a853 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.email-verify-container-after {
  overflow: hidden;
  position: relative;
}

.email-verify-container-after::after {
  top: 0;
  left: 0;
  content: "";
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #34a853;
}

.locationIQ-marker {
  height: 34px;
  margin-top: -17px;
  margin-right: -17px;
  width: 34px;
  padding: 0;
  background-repeat: no-repeat;
}

.stylish-textarea {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
  /* resize: vertical; */
  resize: none;
}

.stylish-textarea:focus {
  border: 2px solid #aadaff;
  /* border-color: # !important; */
  box-shadow: 0 0 8px rgba(133, 183, 217, 0.6);
  outline: none;
}

.stylish-textarea:hover {
  border-color: #aadaff;
}

.ui.inverted.blue.button.borderless {
  border: none !important;
  box-shadow: none !important;
}

.ui.inverted.green.button.borderless {
  border: none !important;
  box-shadow: none !important;
}

.ui.inverted.red.button.borderless {
  border: none !important;
  box-shadow: none !important;
}

.overlay-color {
  background-color: rgba(0, 0, 0, 0.4);
  color: whitesmoke;
}

/* CATALOGUE CARD ANIMATIONS */

@keyframes expandBackground {
  0% {
    width: 0;
    right: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes fadeInItem {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes showInItem {
  0% {
    opacity: 0;
    /* transform: translateX(20px); */
  }

  100% {
    opacity: 1;
    /* transform: translateX(0); */
  }
}

@keyframes showOutItem {
  0% {
    opacity: 1;
    /* transform: translateX(20px); */
  }

  100% {
    opacity: 0;
    /* transform: translateX(0); */
  }
}

.item-animate {
  opacity: 0;
  animation: fadeInItem 0.75s forwards;
}

.item-animate-fast {
  opacity: 0;
  animation: showInItem 0.3s forwards;
}

.item-fade-out {
  opacity: 1;
  animation: showInItem 0.3s backwards;
  animation-delay: 0.2s;
}

.item-show-out {
  opacity: 1;
  animation: showOutItem 0.3s forwards;
  animation-delay: 0.2s;
}

/* Delay for each item */
.item-animate.minus {
  animation-delay: 0.4s;
}

.item-animate.number {
  animation-delay: 0.2s;
}

.item-animate-fast.plus {
  animation-delay: 0.2s;
}

/* REQUEST TO ADD TO INVENTORY BUTTON */

.request-for-inventory {
  cursor: pointer;
}

.requested-for-inventory {
  filter: invert(65%) sepia(100%) saturate(4000%) hue-rotate(100deg) brightness(70%) contrast(100%);
}

.request-for-inventory:hover {
  /* color: #34A853; */
  filter: invert(65%) sepia(100%) saturate(4000%) hue-rotate(100deg) brightness(70%) contrast(100%);
}

/* EARLY ACCESS BUTTON */

.button-early-access {
  background-color: #4caf50;
  /* Green background */
  color: white;
  /* White text */
  padding: 10px 20px;
  /* Padding around the text */
  text-align: center;
  /* Center-aligned text */
  text-decoration: none;
  /* No underline */
  display: inline-block;
  /* Inline block display */
  font-size: 16px;
  /* Font size */
  font-weight: bold;
  /* Bold text */
  letter-spacing: 1px;
  /* Spacing between letters */
  border-radius: 25px;
  /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Shadow for depth */
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  /* Transition for hover effects */
}

.button-early-access:hover {
  background-color: #45a049;
  /* Darker green on hover */
  transform: translateY(-2px);
  /* Move up slightly on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Bigger shadow on hover */
}

.button-early-access::before {
  /* Add your icon URL here */
  padding-right: 8px;
  vertical-align: middle;
}

.reserve-spot-img {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
}

.reserve-spot-img.mobile {
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  /* max-height: 250px; */
  /* object-fit: ; */
}

.readable-text {
  font-size: 16px;
  letter-spacing: 0.7px;
}

/* DeliveryTimeSelector */

.timeline-container {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  /* background-color: #f4f4f4; */
  border-radius: 8px;
}

.time-slot {
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.time-slot .selected {
  background-color: #4caf50;
  color: #ffffff;
}

/* .time-slot.fully-booked {
  background-color: #ccc;
  color: #767676;
  text-decoration: line-through;
  cursor: not-allowed;
} */

.invisible-segment {
  border: none !important;
  box-shadow: none !important;
  margin: 0px !important;
}

.time-slot .fully-booked {
  background-color: #ff6347;
  /* Tomato red */
  color: white;
}

.time-slot .fully-booked:hover::after {
  content: "Unavailable";
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #555;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
}

/* PAGE NOT FOUND CSS */

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  min-height: 100vh;
}

.game-board {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: repeat(3, 100px);
  grid-gap: 10px;
  margin-bottom: 20px;
}

.game-board button {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  background-color: #fff;
  border: 2px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s;
}

.game-board button:hover {
  background-color: #eaeaea;
}

.game-status {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.replay-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.replay-button:hover {
  background-color: #0056b3;
}

.refresh-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background-color: #28a745;
  /* A green color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.refresh-button:hover {
  background-color: #218838;
}

.game-header h1 {
  font-size: 26px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.game-header .subheader {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  text-align: center;
}

.original-price,
.discounted-price {
  display: inline-block;
  font-size: 19px;
  font-weight: 600;
  margin: 5px;
}

.original-price {
  color: grey;
  text-decoration: line-through;
}

.currency-icon {
  margin-right: 5px;
}

.stabbilta-redirect {
  color: #ee4f34;
  font-weight: 400;
  font-size: 15px;
}

.stabbilta-redirect:hover {
  cursor: pointer;
}

.stabbilta-product-image {
  max-height: 130px;
  min-height: 130px;
  object-fit: contain;
  /* width: 100%; */
  margin: auto;
  display: block;
  aspect-ratio: 1;
  overflow-clip-margin: content-box;
  overflow: clip;
  position: fixed;
}

.stabbilta-label {
  left: calc(100% + 1.5rem) !important;
}

.new-green {
  color: #10985d;
}

.new-orange {
  color: #f49d6e;
}

.ui.top.right.pointing.dropdown>.menu {
  z-index: 101;
}

.draggable-bubble {
  position: fixed;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  background-color: #10985d;
  touch-action: none;
  display: block;
}

.draggable-bubble svg {
  width: 100%;
}

.draggable-bubble .bubble-content {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 25%;
  background-color: #f6830c;
  color: white;
  z-index: 100;
  box-shadow: 0px 2px 17px -1px rgba(0, 0, 0, 0.3);
}

.draggable-bubble ul {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.draggable-bubble ul li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6b26bb;
  color: black;
  list-style-type: none;
  transform: scale(0.95);
  transition: 0.5s;
  border-radius: 25%;
}

.draggable-bubble.target-active li:hover {
  color: white;
}

.draggable-bubble.target-active.left li:nth-child(3) {
  top: 0%;
  left: 300%;
  transition-delay: 0s;
}

.draggable-bubble.target-active.left li:nth-child(2) {
  top: 0%;
  left: 200%;
  transition-delay: 0.2s;
}

.draggable-bubble.target-active.left li:nth-child(1) {
  top: 0%;
  left: 100%;
  transition-delay: 0.4s;
}

.draggable-bubble.target-active.right li:nth-child(3) {
  top: 0%;
  left: -300%;
  transition-delay: 0s;
}

.draggable-bubble.target-active.right li:nth-child(2) {
  top: 0%;
  left: -200%;
  transition-delay: 0.2s;
}

.draggable-bubble.target-active.right li:nth-child(1) {
  top: 0%;
  left: -100%;
  transition-delay: 0.4s;
}

.draggable-bubble .bubble-details-pane {
  max-width: 300px;
  height: 100%;
  border-radius: 25%;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  color: white;
  visibility: hidden;
  display: grid;
  column-gap: 5px;
  font-size: 12px;
}

.draggable-bubble .bubble-details-pane .status-line {
  text-align: center;
}

.draggable-bubble .bubble-details-pane .order-line {
  text-align: center;
  width: 170px;
}

.draggable-bubble .bubble-details-pane .order-border-top {
  border-top: #ffffff 0.5px solid;
}

.draggable-bubble .bubble-details-pane .order-border-bottom {
  border-bottom: #ffffff 0.5px solid;
}

.draggable-bubble .bubble-details-pane .order-partial-container {
  width: 170px;
  margin: auto;
}

.draggable-bubble .bubble-details-pane a {
  color: #fff;
  text-decoration: none;
}

.draggable-bubble .smaller-text {
  font-size: 8px;
  line-height: 10px;
}

.draggable-bubble .small-text {
  font-size: 10px;
  line-height: 15px;
}

.draggable-bubble .order-icon-text {
  font-size: 10px;
  margin: auto;
  margin-top: 0%;
}

.draggable-bubble .bubble-content .order-bubble-icon {
  font-size: 20px;
  margin-top: 20%;
  margin-left: 18%;
}

.draggable-bubble .bubble-content .bubble-exit {
  position: absolute;
  background: white;
  border-radius: 50%;
  color: gray;
  top: -8px;
  font-size: 8px;
  width: 17px;
  height: 17px;
}

.draggable-bubble.right .bubble-content .bubble-exit {
  right: -8px;
}

.draggable-bubble.left .bubble-content .bubble-exit {
  left: -8px;
}

.draggable-bubble.target-active.right .bubble-details-pane {
  background-color: #0c975b;
  left: -300% !important;
  padding: 0% 150%;
  border-radius: 16px;
  visibility: visible;
}

.draggable-bubble.target-active.left .bubble-details-pane {
  background-color: #0c975b;
  padding: 0% 150%;
  border-radius: 16px;
  visibility: visible;
}

.ct-green-text {
  color: #10985d !important;
}

.ct-green-text-dark {
  color: #086c41 !important;
}

.ct-green-text-dark.selected-menu-item {
  color: #086c41 !important;
  font-weight: 800 !important;
}

.main-header-menu a:not(.exclude)::after {
  content: '';
  position: absolute;
  bottom: -3px;
  /* Adjust based on your design */
  left: 50%;
  width: 0%;
  /* Initially the underline width is 0 */
  height: 2px;
  /* Height of the underline */
  background: #086c41;
  /* Color of the underline */
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.main-header-menu a:not(.exclude):hover::after {
  width: 100%;
}


.main-header-menu a:hover {
  color: #086c41 !important;
  background: none !important;
  background-color: none !important;
}


.ct-green-button {
  color: #10985d !important;
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0 1px #10985d inset !important;
  box-shadow: 0 0 0 1px #10985d inset !important;
}

.ui.button:disabled.ct-green-button {
  -webkit-box-shadow: 0 0 0 1px #10985d inset !important;
  box-shadow: 0 0 0 1px #10985d inset !important;
}

.ct-green-button-active {
  background-color: #10985d !important;
  color: white !important;
  text-shadow: none;
  background-image: none;
}

.ct-green-button-active-toggle {
  background-color: #10985d !important;
  color: white !important;
  text-shadow: none;
  background-image: none;
  box-shadow: 0 0 0 1px #10985d inset !important;
}

.ct-tangerine-text {
  color: #f49d6e !important;
  width: 200px !important;
}

.ui.dropdown.reschedule>.menu {
  z-index: 101 !important;
  min-width: 250px !important;
}

.ui.dropdown.reschedule>.icon {
  display: none !important;
}

.no-results {
  padding-top: 25px;
  text-align: center;
  max-width: 50vh;
  margin-left: auto;
  margin-right: auto;
}

.no-results .image {
  height: 15vh;
}

.no-results .header {
  margin-bottom: 0px !important;
  margin-top: 3px !important;
  color: grey !important
}


.catalogue-quantity {
  z-index: 1;
  position: absolute;
  padding: 5px;
  right: -1px;
  top: -1px;
  border-radius: 0px 0px 0px 15px;
  /* background-color: #f49d6e !important; */
  color: white !important;
  background-color: #10985d !important;
  font-weight: 600;
  text-shadow: none;
  background-image: none;
}

.catalogue-delivery {
  height: 64px !important;
  width: 64px !important;
  z-index: 1;
  position: absolute;
  padding: 5px;
  left: 7px;
  top: -1px;
  border-radius: 0px 0px 0px 15px;
  /* background-color: #f49d6e !important; */
  /* color: white !important; */
  background-color: transparent !important;
  font-weight: 600;
  text-shadow: none;
  background-image: none;
}

.category-bar {
  z-index: 2;
  /* position: sticky;
  top: 30;
  left: 0; */
  /* width: 250px;*/
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #fff;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.category-bar::-webkit-scrollbar {
  display: none;
}

.category-bar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  /* line-height: 3.0rem; */

}

.category-bar li {
  /* Other styles... */
  padding-left: 20px;
  margin-bottom: 18px;
  /* Or any other value that suits your design */
}

.category-bar li:last-child {
  margin-bottom: 0;
}

/* You could also add styles for nested lists */
.category-bar li ul {
  padding-left: 20px;
  /* This will indent sub-categories */
}

.category-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* font-weight: 600; */
  font-size: 16px;
  cursor: pointer;
}

.category-item.has-items {
  font-weight: 600;
}

.category-icon {
  margin-right: 5px;
  height: 25px;
  max-height: 25px;
  max-width: 25px;
  width: 25px;
  object-fit: contain;
}

.category-description {
  flex-grow: 1;
}

.category-toggle {
  padding: 10px;
  width: 30px !important;
  height: 30px !important;
  margin-right: 5px;
}

.category-toggle.open {
  transform: rotate(180deg);
}

.category-children {
  /* Initially hidden */
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  /* padding-top: 10px; */
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.category-children::-webkit-scrollbar {
  display: none;
}

.documents p {
  font-size: 18px;
}

.faq {
  overflow: hidden;
}

.faq-title {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 18px !important;
}

.faq-title.ct-green-text {
  font-weight: 600;
}

.faq-img {
  margin-top: 2px;
  max-width: 15px;
  width: 15px;
  height: 15px;
}

.faq .content {
  font-size: 16px !important;
  margin-left: 26px !important;
  padding-right: 8px !important;
  transform-origin: top !important;
  transition: max-height 1.5s ease, transform 0.5s ease !important;
  height: 0;
  display: flex !important;
}

.faq .content.closed {
  transform: scaleY(0) !important;
}

.faq .content.active {
  height: auto;
  transform: scaleY(1) !important;
  display: inline-block !important;
}

/* NavBar */
header>div {
  @media screen and (max-width: 768px) {
    height: 50px !important;
    margin-top: 0px !important;
    background-color: #fff !important;
  }
}

header {
  @media screen and (max-width: 768px) {
    top: -20px !important;
  }
}

/* 
header::before,
header::after {
  @media screen and (max-width: 768px) {
    height: 0px !important;
  }
} */

.searchWrapper {
  cursor: pointer;

  @media only screen and (min-width: 991px) and (max-width: 1366px) {
    opacity: 1;
  }
}

.hub-order-verify-table .positive-order {
  background-color: #0c752bb0 !important;
}

.hub-order-verify-table .warning-order {
  background-color: #c4c10f !important;
}

.hub-order-verify-table .error-order {
  background-color: #c4150f !important;
}

.scanned-product .segment {
  background-color: #c4760f !important;
}

.catalogue-sort-by-holder {
  font-size: 14px;
  font-weight: 700;
  color: #2e2f32;
  letter-spacing: .6px;
  margin-left: .5rem;
  margin-right: .5rem;
}

.catalogue-sort-by-option {
  margin-top: 13px;
}

.brand-sort-by-selected {
  font-size: 14px;
  min-width: 70px !important;
  width: auto !important;
  max-width: 130px !important;
}

.catalogue-sort-by-selected {
  font-size: 13px;
  /* min-width: 150px !important; */
  width: auto !important;
  max-width: 170px !important;
}

.catalogue-animate {
  opacity: 1;
  animation: showInItem 0.5s forwards;
}

.catalogue-animate-out {
  opacity: 0;
  animation: showOutItem 0.5s forwards;
}

.savings-widget {
  position: fixed;
  z-index: 10000;
  width: 70px;
  height: 70px;
  background-color: #10985d;
  color: white;
  text-align: center;
  line-height: 12px;
  cursor: grab;
  user-select: none;
  bottom: 25px;
  border-radius: 50%;
  right: 25px;
  box-shadow: 0 0 8px 2px rgba(8, 108, 65, 0.5);
  transition: box-shadow 0.3s ease;
}

.savings-widget:hover {
  box-shadow: 0 0 12px 3px rgba(8, 108, 65, 0.75);
  /* Darker shadow on hover for a nice effect */
}

.savings-widget img {
  padding-top: 3px;
  margin-right: 6px;
  width: 40px;
  height: 40px;
}

.savings-widget span {
  font-weight: 600;
  display: block !important;
}

.savings-widget p {
  margin-top: -4px;
}

.savings-popup {
  position: absolute;
  bottom: -23%;
  max-width: 200px;
  left: -100px;
  line-height: 16px !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  transform: translateX(-50%);
  padding: 8px;
  background-color: #fff;
  color: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: none;
}

.savings-widget:hover .savings-popup {
  display: block;
  /* Show the popup on hover */
}

.savings-widget .close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: #f49d6e;
  color: white;
  cursor: pointer;
  padding: 3px 5px;
  font-size: 12px;
  transition: transform 0.3s ease-out;
  transform-origin: center;
  border-radius: 50%;
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dots span {
  display: block;
  width: 6px;
  height: 6px;
  margin: 0 4px;
  font-weight: 600;
  background-color: white;
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

/* Define the keyframes for the bounce animation */
@keyframes bounce {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-15px);
  }
}

/* Apply a slight delay to each dot to create the wave effect */
.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

.discount {
  width: 50px;
  height: 50px;
  z-index: 104;
  position: absolute;
  padding: 5px;
  right: -7px;
  top: -8px;
  /* border-radius: 50px 0px 50px 50px; */
  border-radius: 50px 50px 50px 0;
  /* background-color: #f49d6e !important; */
  color: white !important;
  background-color: #ca150f !important;
  font-weight: 600;
  text-shadow: none;
  background-image: none;
}

.discount.chat {
  width: 40px;
  height: 40px;
  z-index: 104;
  position: absolute;
  padding: 3px;
  right: -7px;
  top: -8px;
  /* border-radius: 50px 0px 50px 50px; */
  border-radius: 50px 50px 50px 0;
  /* background-color: #f49d6e !important; */
  color: white !important;
  background-color: #ca150f !important;
  font-weight: 600;
  text-shadow: none;
  background-image: none;
}

.line1.chat {
  color: white;
  font-weight: 600;
  font-size: 10px;
  position: absolute;
  top: 4px;
  left: 6px;
}

.line2.chat {
  color: white;
  font-weight: 600;
  font-size: 12px;
  position: absolute;
  top: 16px;
  left: 3px;
}

.line1 {
  color: white;
  font-weight: 600;
  font-size: 11px;
  position: absolute;
  top: 7px;
  left: 9px;
}

.line2 {
  color: white;
  font-weight: 600;
  position: absolute;
  top: 22px;
  left: 5px;
}

.next-delivery-slot {
  background-color: #059228 !important;
  border: 1px solid #059228 !important;
  color: #fff !important;
  border-radius: 8px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8) !important;
  font-size: 1.15em;
}

.strikethrough {
  position: relative;
  display: inline-block;
}

.strikethrough::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1.5px;
  background-color: rgba(255, 0, 0, 0.8);
  top: 50%;
  transform: translateY(-50%) rotate(10deg);
  transform-origin: center;
}

.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  position: relative;
  cursor: grab;
}

.carousel img {
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease-in-out;
}

.carousel button {
  margin: 5px;
  padding: 10px;
  cursor: pointer;
}

.zoom-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}


.brands-search-list {
  max-height: 250px !important;
  overflow-y: auto !important;
}

.price-drop-indicator {
  max-width: 30px;
  max-height: 30px;
  top: -20px;

}

.custom-divider {
  width: 1px;
  height: 50px;
  background-color: #757373;
  margin: 6px 20px;
}


/* CHAT UI START */
.chef-sidebar {
  display: block;
  scrollbar-color: transparent transparent !important;
  /* position: relative; */
  max-height: 96vh;
  height: 96vh;
  /* top: 10; */
  /* left: 0; */
  padding-left: 10px;
  padding-right: 10px;
  border-right: 1px solid white;
  overflow-y: auto;
  /* overflow-x: auto; */
  min-width: 250px;
  background:
    linear-gradient(to right, #f4f4f4 0%, #f4f4f4 calc(100% - 10px), white) no-repeat,
    linear-gradient(to top, #f4f4f4 0%, #f4f4f4 calc(100% - 10px), white) no-repeat;
  /* transition: transform 0.4s ease-in-out; */
  transition: width 5s ease;
}

.chef-sidebar.closed {
  /* transform: translateX(-100%); */
  min-width: 0px !important;
  max-width: 0px !important;
  width: 0px !important;
  padding: 0px !important;
  /* position: fixed;
  display: none; */
}

/* Open state: Set transform to zero, so it's visible */
.chef-sidebar.open {
  min-width: 250px;
  /* transform: translateX(0); */
}


.sidebar-chat-list {
  list-style: none;
  padding: 0;
  margin-top: 15px !important;
  padding-left: 3px;
  color: black;
  font-size: 16px;
  max-height: 96vh;
  height: 96vh;
  overflow-y: auto;
}

.sidebar-chat-list .active.item {
  background-color: #10985d5b;
  border-radius: 10px;
  /* background: */
  /* linear-gradient(to right, #10985d5b 0%, #f4f4f4 calc(100% - 1px), white) no-repeat; */
}

.sidebar-chat-list .item {
  padding-top: 8px !important;
  padding-left: 7px !important;
  padding-bottom: 8px !important;
  max-width: 300px !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  position: relative !important;
}

.sidebar-chat-list .item:hover {
  overflow: visible !important;
  white-space: normal !important;
  -webkit-line-clamp: none !important;
  background-color: #10985d5b;
  border-radius: 10px;
}

.sidebar-chat-list .item:hover::after {
  display: none !important;
}

.sidebar-chat-list .item::after {
  content: "" !important;
  position: absolute !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 50px !important;
  height: 100% !important;
}

.chat-messages {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  height: 86vh;
  max-height: 87vh;
  background-color: white;
}

.user-message {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.assistant-message {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.message-bubble {
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 16px;
  line-height: 20px;
  white-space: pre-wrap;
  background-color: #f4f4f4;
  color: rgb(49, 49, 49);
}

.message-bubble-assistant {
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 16px;
  line-height: 20px;
  white-space: pre-wrap;
  color: rgb(49, 49, 49);
}

/* 
.typewriter p {
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  max-width: 60%;
  width: 0;
  animation: typing 4s steps(30, end), fadeout 1s forwards 5s;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
} */


.welcome-card-container {
  justify-content: center !important;
  align-items: center !important;
}

.welcome-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 200px;
  margin: 5px 10px;
  text-align: left;
  transition: transform 0.3s ease;
  min-height: 150px;
  height: 150px;
}

.welcome-card:hover {
  transform: translateY(-10px);
}

.welcome-card img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.welcome-card p {
  font-size: 16px;
  color: #333;
  font-size: 16px;
  line-height: 18px;
}


/* CHAT UI END */

/* RECIPE START */
.recipe-card {
  width: 230px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  font-family: 'Arial', sans-serif;
  margin: 5px;
  transition: transform 0.3s ease;
}

.recipe-card:hover {
  transform: translateY(-10px);
}

.recipe-card-image {
  position: relative;
  margin: 10px;
}

.recipe-card-image img {
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 5px;
}

.like-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.recipe-card-content {
  padding: 10px;
  padding-top: 0px !important;
}

.recipe-title {
  font-size: 1.2rem;
  margin: 10px 0;
}

.recipe-description {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
}

.recipe-card-footer {
  padding: 10px;
  border-top: 1px solid #eee;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}

.recipe-info {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  /* color: #888; */
}

.recipe-info span {
  display: flex;
  align-items: center;
}

.recipe-info .recipe-time::before,
.recipe-info .recipe-difficulty::before,
.recipe-info .recipe-servings::before {
  margin-right: 5px;
}

.recipe-info img {
  height: 24px;
  width: 24px;
}

.recipe-info p {
  margin-top: 5px;
  font-size: 16px;
  text-transform: capitalize,
}

.ingredients-section {
  width: 40%;
  background-color: white;
  padding: 15px;
  box-shadow:
    -2px 2px 2px rgba(0, 0, 0, 0.1),
    2px 2px 2px rgba(0, 0, 0, 0.1),
    0px 2px 2px rgba(0, 0, 0, 0.1);

}

.ingredients-section.mobile {
  width: 100%;
}

.execution-section {
  padding: 15px;
  width: 50%;
}

.execution-section.mobile {
  padding: 15px;
  width: 100%;
}

.ingredients-section h2,
.execution-section h2 {
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}


h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

li {
  margin-bottom: 10px;
}

label {
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.panel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 75%;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  z-index: 1000;
  background-color: #fff;
  overflow-y: auto;
}

.panel.mobile {
  width: 100%;
}

.panel.open {
  transform: translateX(0);
}

.recipe-detail-header {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 35px;
  background-color: #086c41 !important;
  position: relative;

}

.recipe-detail-header .recipe-info {
  margin-top: 35px !important;
}

.recipe-detail-container {
  margin: 0 auto;
  overflow-y: auto;
}

.recipe-detail {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 100px 0px;
  margin-bottom: 50px;
}

.recipe-detail.mobile {
  margin: 0 auto;
  padding: 0px 0px 0px;
  margin-bottom: 50px;
}

.recipe-detail-header-name {
  font-size: 37px;
  font-weight: 400;
  line-height: 1.3;
  color: white;
  text-align: center;
  font-family: Source Sans Pro, sans-serif;
}

.recipe-detail-source {
  font-size: 17px;
  text-decoration: underline;
  font-weight: 400;
  line-height: 1.3;
  color: white;
  text-align: center;
  font-family: Source Sans Pro, sans-serif;
}

.recipe-detail-close {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 7px;
  padding-left: 9px;
  color: white;
}

.recipe-detail-description {
  color: #fff;
  max-width: 840px;
  padding: 30px 10px 10px;
  margin: 0 auto;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: Source Sans Pro, sans-serif;
}

.recipe-tip-container {
  font-style: italic;
  color: grey;
}

.recipe-tip-container div {
  text-align: center;
  line-height: 15px;
  border: 1px solid grey;
  border-radius: 25px;
  height: 25px;
  min-width: 45px;
  padding: 3px;
  padding-bottom: 5px;
  margin-right: 8px;
}


/* Timeline container */
.timeline {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

/* Timeline item */
.timeline li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */

}

/* Circle (acting as checkbox) */
.timeline .checkbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 23px;
  height: 23px;
  border: 2px solid #bfbfbf;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}

/* Checked (completed) state */
.checkbox.completed {
  background-color: #086c41;
  border-color: grey;
}

/* Line connector */
.timeline li::after {
  content: '';
  position: absolute;
  left: 10px;
  top: 23px;
  width: 2px;
  height: calc(100%);
  background-color: #bfbfbf5d;
}

.timeline li.completed::after {
  background-color: #086c41 !important;
}

/* Last item should not have a connector */
.timeline li:last-child::after {
  display: none;
}

.timeline li p {
  width: 100%;
  padding: 10px 24px;
  font-size: 16px;
  display: block;
  background: #fff;
  border-radius: 8px;
  color: #171717;
  border: 1px solid #e5e5e5;
}

.recipe-ingredients-search {
  padding: 15px;
  width: 60%;
}

.recipe-ingredients-title {
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 18px !important;
  background-color: #086c41;
  color: white !important;
}

/* RECIPE END */



/* Diet Profile Setup START*/
.green-border-circle {
  width: 12px;
  height: 12px;
  background-color: white;
  border: 2.5px solid #086c41;
  border-radius: 50%;
  display: inline-block;
}

.setup-diet-box {
  position: relative;
  border: 1.5px solid #086c41 !important;
  border-radius: 5px;
  padding: 14px !important;
  margin-top: 7px;
  width: 100%;
}

.diet-continue-button {
  max-width: 150px;
  background-color: #086c41 !important;
  color: white !important;
  border-radius: 10px;
  font-size: 18px;
  padding: 10px 17px 10px 17px;
}

.diet-continue-button.disabled {
  background-color: #086c415b !important;
}

.diet-info {
  width: 25px;
  height: 25px;
  z-index: 104;
  position: absolute;
  padding: 2px;
  right: 0px;
  top: 0px;
  /* border-radius: 50px 0px 50px 50px; */
  border-radius: 0 0 0 10px;
  /* background-color: #f49d6e !important; */
  background-color: #086c416c !important;
  text-shadow: none;
  background-image: none;
}

.diet-image {
  max-width: 45px;
  min-width: 45px;
  width: 45px;
  max-height: 45px;
  min-height: 45px;
  height: 45px;
}

.diet-food-item-box {
  user-select: none;
  display: table;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 5px;
  border: #086c41 solid 1px;
  margin: 0 7px 10px 7px;
  padding: 8px 15px 8px 15px !important;
}

.diet-food-item-box.selected {
  color: white;
  background-color: #086c41;
}

/* Diet Profile Setup END*/


/* advert panel in catalogue */
.ad-panel-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}


.ad-panel-nav {
  position: absolute;
  margin: auto;
  color: #b78876;
}

.ad-panel-nav.left {
  left: 0;
}

.ad-panel-nav.right {
  right: 0;
}

.ad-panel-nav:hover {
  cursor: pointer;
}

.ad-panel-nav.close {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: "pointer";
}