.searchWrapper {
  cursor: pointer;
  color: #0D1136;

  svg {
    display: block;
    width: 17px;
    height: auto;
  }

  @media only screen and (min-width: 991px) and (max-width: 1366px) {
    opacity: 1;
  }
}

.headerSearchWrapper.searchActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.headerSearchWrapper.searchInactive {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.headerSearchWrapper-enter {
  opacity: 0;
  transform: scale(0.9);
}

.headerSearchWrapper-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.headerSearchWrapper-exit {
  opacity: 1;
}

.headerSearchWrapper-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.ui.menu.fluid {
  margin: 0 5px;
  padding-top: 0px;
  height: 60px;
}

header div.ui.menu .item form {
  height: 40px;
  /* margin-left: 6%; */

  @media only screen and (max-width: 991px) {
    max-width: 400px;
  }
}

header div.ui.menu .item .drawer {
  /* margin-left: 6%; */

  @media only screen and (max-width: 991px) {
    min-width: none !important;
    width: 400px !important;
  }
}

.ui.secondary.menu .item {
  @media only screen and (max-width: 991px) {
    padding: 0.78571429em 0.4em;
  }

  @media only screen and (max-width: 815px) {
    padding: 0.78571429em 0.3em;
    font-size: smaller;
  }
}

header {
  top: -5px;
  z-index: 900;
}

header::before {
  box-shadow: none;
  top: 0px;
}

/* header::after {
    content: '';
    display: block;
    height: 20px;
    position: sticky;
    -webkit-position: sticky;
  } */

.searchMenuIcon {
  cursor: pointer;
}

.searchMenuIcon:hover {
  color: #086a3d !important;
}