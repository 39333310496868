.bannerBox {
    height: 200px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    /* -webkit-justify-content: center; */
    -ms-flex-pack: center;
    /* justify-content: center; */
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative;

    @media (max-width: 990px) and (min-width: 768px) {
        height: 200px;
    }

    @media (max-width: 768px) {
        display: none;
    }

    @media screen and (min-width: 767px) {
        height: 100vh;
    }
}

.bannerImg {
    /* background-size: cover; */
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;

    @media (max-width: 990px) and (min-width: 768px) {
        background-position: inherit;
    }
}

.bannerContent {
    /* font-size: 15px; */
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 28vh;
    position: relative;

    @media screen and (min-width: 767px) {
        padding-top: 0;
    }
}

.bannerTitle {
    /* color: #0D1136; */
    color: #353925;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
    font-family: "Kalam", cursive;

    @media screen and (min-width: 991px) {
        font-size: 45px !important;
    }

    @media screen and (min-width: 767px) {
        font-size: 40px;
    }
}

.bannerDescription {
    font-size: 22px !important;
    font-family: "Patrick Hand", cursive;
    color: #646464;
    display: none;
    font-weight: 400;
    line-height: 1;
    text-align: center;

    @media screen and (min-width: 767px) {
        font-size: 19px;
        margin-bottom: 40px;
        display: block;
    }
}

.searchWrapper {
    display: flex;
    justify-content: center;
}